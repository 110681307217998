body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "work-sans-500";
  src: url("./assets/fonts/Work-sans/work-sans-500.ttf") format("truetype");
}
@font-face {
  font-family: "work-sans-600";
  src: url("./assets/fonts/Work-sans/work-sans-600.ttf") format("truetype");
}
@font-face {
  font-family: "work-sans-700";
  src: url("./assets/fonts/Work-sans/work-sans-700.ttf") format("truetype");
}
@font-face {
  font-family: "work-sans-600";
  src: url("./assets/fonts/Work-sans/work-sans-600.ttf") format("truetype");
}
@font-face {
  font-family: "work-sans-500";
  src: url("./assets/fonts/Work-sans/work-sans-500.ttf") format("truetype");
}
@font-face {
  font-family: "inter-700";
  src: url("./assets/fonts/Inter/inter-700.ttf") format("truetype");
}
@font-face {
  font-family: "Inter-Black.ttf";
  src: url("./assets/fonts/Inter/Inter-Black.ttf") format("truetype");
}
@font-face {
  font-family: "Inter-Bold.ttf";
  src: url("./assets/fonts/Inter/Inter-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Inter-ExtraBold.ttf";
  src: url("./assets/fonts/Inter/Inter-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "Inter-Light.ttf";
  src: url("./assets/fonts/Inter/Inter-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Inter-Medium.ttf";
  src: url("./assets/fonts/Inter/Inter-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Inter-Regular.ttf";
  src: url("./assets/fonts/Inter/Inter-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Inter-SemiBold.ttf";
  src: url("./assets/fonts/Inter/Inter-SemiBold.ttf") format("truetype");
}